const BCImageLoader = require('bc-image-loader');

const $ = require('jquery');
require('slick-carousel');
const _ = require('underscore');

// Initialize all global stuff with data from back-end
const { initGlobals } = require('../../common/js/commonSetup');
initGlobals();

require('../../../../shared/lib/handlebarsHelpersTime')();
require('../../common/js/sorting');
const { videoFinisher } = require('../../common/js/videofinisher');
const { setupPlayers, teardownPlayers } = require('../../common/js/perform');
const utils = require('../../common/js/utils');

const MOBILE = 1;
const TABLET = 600;
const DESKTOP = 992;
const DESKTOP_MAX = 1200;
const VIDEO_ASPECT_RATIO = 16 / 9;
const STATUS_UPDATE_INTERVAL = 60 * 1000;
const TEMPLATE_DOWNLOAD_LINK = window.bcGallery.getTemplatePath('/components/clientDownloadLink.hbs');

const liveStatus = {
  ERROR: 'error',
  STANDBY: 'standby',
  WAITING: 'waiting',
  PROCESSING: 'processing',
  DISCONNECTED: 'disconnected',
  CANCELLING: 'cancelling',
  FINISHING: 'finishing',
  CANCELLED: 'cancelled',
  FINISHED: 'finished',
  FAILED: 'failed',
};

//---------------------------------------------------------------------------------
// Main
class Main {
  constructor () {
    this.isFixedHeader = false;
    this.isTouchDevice = false;
  }

  init () {
    this.scrollbarWidth = this.getScrollbarWidth();
    this.isFixedHeader = this.determineIsFixedHeader();
    this.isTouchDevice = this.determineIsTouchDevice();

    if (this.isTouchDevice) {
      $('body').removeClass('no-touch');
    }

    if (this.isFixedHeader) {
      $('#bc-site-container').addClass('fixed-header');
    }

    $('.view-more-bar-link').on('click', function (e) {
      e.preventDefault();

      const $videoGrid = $(e.currentTarget).closest('.video-grid-with-more');
      $videoGrid.addClass('open');

      $(e.currentTarget).parent().remove();
    });

    $('.video-grid').on('click', '.video-image-info-button', e => {
      // this is a touch device only behavior
      if (this.isTouchDevice) {
        e.preventDefault();

        // close all overlays
        $('.video-grid .metadata-overlay').hide();

        // show this overlay
        const $gridItem = $(e.currentTarget).closest('.video-grid-item');
        const $gridItemOverlay = $gridItem.find('.metadata-overlay');
        $gridItemOverlay.show();
      }
    });

    $('.video-grid').on('click', '.metadata-overlay', e => {
      // this is a touch device only behavior
      if (this.isTouchDevice) {
        e.preventDefault();
        $(e.currentTarget).hide();
      }
    });

    $('.pagination-container').on('keyup', '.pagination-current', e => {
      if (e.keyCode === 13) {// enter
        this.gotoPage($(e.currentTarget));
      }
    });

    $(window).resize(() => {
      this.onWindowResize();
    });
    this.onWindowResize();

    setTimeout(() => {
      this.onWindowResize();
    }, 20);

    this.imageLoader = new BCImageLoader({
      transcoder: global.BCLS.imageTranscoder,
    });

    // always load images in 16:9 ratio, helps IE 11
    this.imageLoader.ignoreHeight = true;
  }

  onWindowResize () {
    const dimensions = this.getDimensions();
    detail.resize(dimensions);
    hero.resize(dimensions);
    header.resize(dimensions);
    carousel.onWindowResize();
    detail.updateReadMore();

    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(function () {
      hero.resize(dimensions);
      header.resize(dimensions);
    }, 10);
  }

  isMobile () {
    const dimensions = this.getDimensions();
    return dimensions.breakpoint === MOBILE;
  }

  getDimensions () {
    let windowWidth = $(window).width();
    const windowHeight = $(window).height();

    if (windowWidth > DESKTOP_MAX) {
      windowWidth = DESKTOP_MAX;
    }

    let breakpoint = MOBILE;
    let breakpointWidth = windowWidth;
    if (this.isScrollbarShowing()) {
      breakpointWidth = breakpointWidth + this.scrollbarWidth;
    }

    if (breakpointWidth >= DESKTOP) {
      breakpoint = DESKTOP;
    } else if (breakpointWidth >= TABLET) {
      breakpoint = TABLET;
    }

    return {
      width: windowWidth,
      height: windowHeight,
      breakpoint: breakpoint,
    };
  }

  getScrollbarWidth () {
    let $scrollbar = $('#bc-scrollbar-check');

    if ($scrollbar.length <= 0) {
      $scrollbar = $('<div class="scrollbar-check" id="bc-scrollbar-check">');
      $scrollbar.prependTo($('body'));
    }

    return $scrollbar.get(0).offsetWidth - $scrollbar.get(0).clientWidth;
  }

  isScrollbarShowing () {
    return $(document).height() > $(window).height();
  }

  gotoPage ($input) {
    let newPage = parseInt($input.val()) - 1;
    const maxPage = parseInt($input.attr('data-bc-total-pages'));

    if (newPage < 0) {
      newPage = 0;
    } else if (newPage >= maxPage) {
      newPage = maxPage - 1;
    }

    $input.val(newPage + 1);

    let href = document.location.href;
    if (href.indexOf('?page=') >= 0 || href.indexOf('&page=') >= 0) {
      href = href.replace(/page=[0-9]+/, 'page=' + newPage);
    } else {
      if (href.indexOf('?') >= 0) {
        href += '&page=' + newPage;
      } else {
        href += '?page=' + newPage;
      }
    }

    document.location.href = utils.urlWithDevice(href);
  }

  determineIsFixedHeader () {
    return parseInt($('#bc-site-container-spacer-top').css('z-index')) > 0;
  }

  determineIsStudio () {
    // bc-studio class added via page editor
    return $('body').hasClass('bc-studio');
  }

  determineIsTouchDevice () {
    return typeof window.ontouchstart !== 'undefined';
  }
}

class Header {
  constructor () {
    this.isCategoriesListOpen = false;
    this.isCategoriesAlwaysOpen = false;
  }

  init () {
    // initialize as such but will be overriden by "resize" function
    this.isCategoriesAlwaysOpen = this.determineCategoriesAlwaysOpen();

    if (this.isCategoriesAlwaysOpen) {
      this.setSiteContainerSpacing();
      this.setCategoriesListOpenWithoutChecks(true, true);
    }

    $('#bc-main-header-search-icon').on('click', e => {
      e.preventDefault();
      this.setSearchOpen(true);
    });

    $('#bc-main-header-search-cancel').on('click', e => {
      e.preventDefault();
      this.setSearchOpen(false);
    });

    $('#bc-main-header-search-button').on('click', e => {
      e.preventDefault();
      this.performSearch();
    });

    $('#bc-main-header-search-input').on('keyup', e => {
      if (e.keyCode === 13) {// enter key
        if (e.originalEvent.isComposing) { // don't execute search if keyboard is composing input
          return;
        }
        this.performSearch();
      }
    });

    $('#bc-main-header-categories').on('click', e => {
      e.preventDefault();
      this.toggleCategories();
    });

    $('.bc-search-button-mobile').on('click', this.stopPlayback);
    $('.navbar-ex1-modal').on('shown.bs.modal', this.stopPlayback);
    $('.bc-video-search-form').on('submit', e => this.performSearchMobile(e));
  }

  stopPlayback () {
    if (window.videojs && window.videojs.players &&
      window.videojs.players.performPlayer) {
      window.videojs.players.performPlayer.pause();
    }
  }

  resize (dimensions) {
    if (main.isMobile()) {
      this.setCategoriesListOpenWithoutChecks(false, true);
    }

    this.setSiteContainerSpacing();

    const $logo = $('#bc-main-header-logo');
    const $categories = $('#bc-main-header-categories');
    const $social = $('#bc-main-header-social');
    const $searchIcon = $('#bc-main-header-search-icon');
    const $search = $('#bc-main-header-search');
    const $home = $('#bc-main-header-home');

    const homeIsEmpty = $home.hasClass('is-empty');
    if (homeIsEmpty && !main.determineIsStudio()) {// reset home width if not in studio
      $home.css('padding', '0px').children('a').css('padding', '0px');
    }

    $searchIcon.removeAttr('style');// measure with default width to start

    const availableWidth = dimensions.width;
    const logoWidth = $logo.hasClass('is-empty') || $logo.outerHeight() <= 0 ? 0 : $logo.outerWidth();
    let searchIconWidth = $searchIcon.length > 0 ? $searchIcon.outerWidth() : 0;
    const socialWidth = $social.is(':visible') ? $social.outerWidth() : 0;
    const homeWidth = $home.length > 0 ? $home.outerWidth() : 0;
    let categoriesWidth = availableWidth - (logoWidth + searchIconWidth + socialWidth + homeWidth + 1);
    /* 1px accounts for pixel shift */
    const searchWidth = availableWidth - logoWidth;

    switch (dimensions.breakpoint) {
      case TABLET:
      case DESKTOP:
        categoriesWidth = 240;
        searchIconWidth = availableWidth - (logoWidth + socialWidth + categoriesWidth + homeWidth + 41);
        /* 40px + 1px accounts for decreased fma button and pixel shift */
        break;
    }

    $searchIcon.width(searchIconWidth);
    $search.width(searchWidth);
    $categories.width(categoriesWidth);

    this.updateMobileModals();
  }

  updateMobileModals () {
    if (!main.isMobile()) {
      $('#nav-modal').modal('hide');
    }
  }

  setSiteContainerSpacing () {
    const $spacer = $('#bc-site-container-spacer-top');
    const spacerHeight = $spacer.height();

    const $header = $('#bc-main-header');
    $header.css('top', spacerHeight);
  }

  setSearchOpen (isOpen) {
    const $search = $('#bc-main-header-search');

    $search.removeClass('open');
    if (isOpen) {
      $search.addClass('open').find('input').focus();
    }
  }

  performSearch () {
    const searchTerm = $('#bc-main-header-search-input').val();
    if (!searchTerm) {
      return;
    }
    document.location.href = utils.urlWithDevice(window.baseUrl + '/search?q=' + encodeURIComponent(searchTerm));
  }

  performSearchMobile (event) {
    event.preventDefault();
    const searchTerm = encodeURIComponent($('#bc-video-search-form-value-mobile').val());
    if (!searchTerm) {
      document.location.href = '/';
    } else {
      document.location.href = utils.urlWithDevice(window.baseUrl + '/search?q=' + searchTerm);
    }
  }

  toggleCategories () {
    this.setCategoriesListOpenWithoutChecks(!$('#bc-categories-list').hasClass('open'), false);
  }

  onDisqusReady () {
    this.resize(main.getDimensions());
  }

  getSiteContainerTopPadding () {
    const padding = parseInt($('.site-container').css('padding-top'));
    return isNaN(padding) ? 0 : padding;
  }

  setCategoriesListOpen (isOpen, skipAnimation) {
    if (this.isCategoriesAlwaysOpen) {
      isOpen = true;
    }

    if (isOpen === this.isCategoriesListOpen) {
      return;
    }

    this.setCategoriesListOpenWithoutChecks(isOpen, skipAnimation);
  }

  setCategoriesListOpenWithoutChecks (isOpen, skipAnimation) {
    this.isCategoriesListOpen = isOpen;

    const $siteContainer = $('#bc-site-container');
    const $mainHeader = $('#bc-main-header');
    const $categoriesHeader = $('#bc-main-header-categories');
    const $categoriesList = $('#bc-categories-list');
    const $videoOverlay = $('#bc-hero-player-overlay');

    let siteContainerMarginTop = $mainHeader.height();

    if (this.isCategoriesListOpen) {
      $categoriesList.height('auto').show();
      siteContainerMarginTop = $mainHeader.height() + $categoriesList.height();
      $categoriesList.hide();

      const categoriesListTop = $mainHeader.offset().top;
      $categoriesHeader.addClass('on');
      if (main.isFixedHeader) {
        $categoriesList.css('top', categoriesListTop);
      }
      $categoriesList.addClass('open').stop().slideDown(skipAnimation ? 0 : 200);
    } else {
      $categoriesHeader.removeClass('on');
      $categoriesList.removeClass('open').stop().slideUp(skipAnimation ? 0 : 200);
    }

    if (main.isFixedHeader) {
      $siteContainer.stop().animate({
        marginTop: siteContainerMarginTop,
      }, {
        duration: skipAnimation ? 0 : 200,
        complete: () => {
          this.resize(main.getDimensions(), true);
        },
      });

      $videoOverlay.stop().animate({
        top: siteContainerMarginTop,
      }, {
        duration: skipAnimation ? 0 : 200,
      });
    }

  }

  determineCategoriesAlwaysOpen () {
    // template sets "always-open" based on category being set or not
    // exceptions to this are cased below
    let alwaysOpen = $('#bc-categories-list').hasClass('always-open');

    // 1) check we are not at mobile width
    const dimensions = main.getDimensions();
    if (dimensions.breakpoint === MOBILE) {
      alwaysOpen = false;
    }

    // 2) check we are not on the video detail page
    // (which has a category as well)
    if ($('#bc-video-detail-wrapper').length > 0) {
      alwaysOpen = false;
    }

    // 3) check that we're not on the homepage
    // (which has a category as well)
    if ($('#bc-hero-container').length > 0) {
      alwaysOpen = false;
    }

    // 4) check that we're not on search
    // (which has a category as well)
    if ($('#search-category-container').length > 0) {
      alwaysOpen = false;
    }

    // 5) check that we are not scrolled
    if ($(window).scrollTop() > 0) {
      alwaysOpen = false;
    }

    // return the value
    return alwaysOpen;
  }
}

class Detail {
  init () {
    $('#bc-view-comments-button').on('click', function (e) {
      e.preventDefault();

      const $comments = $('#bc-video-comments');
      const $commentsButtonLabel = $('#bc-view-comments-button-label');

      $comments.toggleClass('open');
      $commentsButtonLabel.html($comments.hasClass('open') ? 'Hide Comments ' : 'View Comments ');
    });

    this.loadVideoDownload();

    $('.bc-sort-videos-mobile').on('shown.bs.modal', this.styleBackdrop);
    $('.bc-load-more-button').on('click', this.loadMoreVideos.bind(this));

    this.READMORE_HEIGHT = 70;

    $('.bc-read-more-container-mobile').on('click', (e) => {
      e.preventDefault();
      const $description = $('.video-detail .video-description');
      const $relatedLink = $('.video-metadata-mobile .related-link');
      $relatedLink.show();
      const $relatedLinkHeight = $relatedLink.prop('scrollHeight') || 0;
      const $textHeight = $('.video-detail .video-description').prop('scrollHeight');
      $description.height($textHeight + $relatedLinkHeight);
      $description.css('-webkit-line-clamp', 'unset');
      $('.bc-read-more-container-mobile').hide();
      $('.bc-read-less-container-mobile').show();
    });

    $('.bc-read-less-container-mobile').on('click', (e) => {
      e.preventDefault();
      const $description = $('.video-detail .video-description');
      const $relatedLink = $('.video-metadata-mobile .related-link');
      $relatedLink.hide();
      $description.height(this.READMORE_HEIGHT + 'px');
      $description.css({ '-webkit-line-clamp': '3' });
      $('.bc-read-more-container-mobile').show();
      $('.bc-read-less-container-mobile').hide();
      window.scrollTo({
        top: 0,
        let: $description.position().top,
        behavior: 'smooth',
      });
    });
    this.updateReadMore();

    if (window.videojs && window.videojs.players &&
      window.videojs.players.performPlayer) {
      window.videojs.players.performPlayer.on('play', this.hideMobileTitle);
    }
  }

  hideMobileTitle () {
    $('.video-title-mobile').hide();
  }
  styleBackdrop () {
    const $backdrop = $('.modal-backdrop');
    if ($backdrop.length) {
      $('.modal-backdrop').css('opacity','1');
      $('.modal-backdrop').css('background-color','grey');
    }
  }
  loadMoreVideos () {
    const nextPage = $('.bc-load-more-button').data('next-page');
    const match = /page=([a-z0-9]*)/i.exec(nextPage);
    let page;
    if (match && match.length > 0) {
      page = match[1];
    } else {
      page = Math.floor($('.bc-video-grid-items').children().length / this.VIDEOS_PER_PAGE);
    }
    const q = window.query ? window.query.q : null;
    const category = window.category ? window.category.slug : '';
    this.loadVideos({ append: true, page: page, q: q, category: category });
    delete window.query.page;
  }

  loadVideos (params) {
    // reset but not everything so as to not break autoplay next
    if (window.query) {
      window.query.page = null;
      window.query.q = null;
    }

    $('.video-grid').fadeTo(0, 0.5);

    let route = '';

    // recursively loop through categories to find active category
    function recurse (category) {
      if (category.slug === params.category) {
        window.category = category;
        route = '/' + category.slug;
        return;
      }

      if (_.has(category, 'children')) {
        _.each(category.children, function (child) {
          recurse(child);
        });
      }
    }

    if (params.category) {
      _.each(window.categories, function (category) {
        recurse(category);
      });
    }

    if (params.page) {
      window.query.page = params.page;
    }

    if (params.q) {
      window.query.q = params.q;
    }
    if (params.sort_by) {
      window.query.sort_by = params.sort_by;
    }
    return $.ajax(window.baseUrl + '/api/videos' + route, {
      dataType: 'json',
      data: params,
      success: (data) => {
        this.appendVideos(data);
      },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  appendVideos (data) {
    if (!data || !data.result || !data.result.items) {
      return;
    }
    const videos = data.result.items;

    const videoGridItemPath = window.bcGallery.getTemplatePath('/sites/publisher/partials/videoGridItem.hbs');

    hero.loadTemplate(videoGridItemPath, function (template) {
      for (let i = 0; i < videos.length; i++) {
        if (videos[i]) {
          const contextData = {
            baseUrl: window.baseUrl,
            imageTranscoder: window.BCLS.imageTranscoder,
            category: window.category,
            query: window.query,
            subPath: window.subPath,
            translations: window.translations,
          };
          const handlebarContext = _.extend({}, videos[i], contextData);
          $('.video-grid-inner').append(template(handlebarContext));
        }
      }
      main.imageLoader.loadImages();
      $('.video-grid').fadeTo(0, 1);
      const $loadMoreButton = $('.bc-load-more-button');
      if (data.result.next) {
        $loadMoreButton.data('next-page', data.result.next);
      } else {
        $loadMoreButton.hide();
      }
    });
  }

  //Read More/Less Buttons
  updateReadMore () {
    const $description = $('.video-detail .video-description');
    if (!main.isMobile() || $description.prop('scrollHeight') < this.READMORE_HEIGHT + 20) {
      $('.bc-read-more-container-mobile').toggle(false);
      $('.bc-read-less-container-mobile').toggle(false);
      $description.css({ display: 'block', height: 'auto' });
      return;
    }
    const showReadMore = $description.prop('scrollHeight') > 84 && $description.prop('scrollHeight') > $description.height() + 2;
    const showReadLess = !showReadMore && $description.height() > this.READMORE_HEIGHT;
    $('.bc-read-more-container-mobile').toggle(showReadMore);
    $('.bc-read-less-container-mobile').toggle(showReadLess);
    if (!showReadLess && !showReadMore) {
      $description.css({ display: 'block', height: 'auto' });
    }

  }

  isIOS () {
    return global.videojs && global.videojs.browser && global.videojs.browser.IS_IOS;
  }

  loadVideoDownload () {
    const $downloadEl = $('.video-download-placeholder');
    if (!$downloadEl.length || this.isIOS()) {
      return;
    }

    hero.loadTemplate(TEMPLATE_DOWNLOAD_LINK, function (template) {
      window.bcGallery.videoDownloads.getSources(window.currentVideo.id, function (sources) {
        if (!sources.default) {
          return;
        }
        const downloadButtonHtml = template({
          sources: sources,
          translations: window.translations,
        });
        $downloadEl.replaceWith(downloadButtonHtml);
      });
    });
  }

  onDisqusReady () {
    $('#bc-video-comments').removeClass('loading');
  }

  resize (dimensions) {
    const $videoWrapper = $('#bc-video-detail-wrapper');
    const videoHasCta = $('.video-detail').hasClass('with-cta');

    // width depends on the existence of a CTA at desktop width
    let videoWidth = $videoWrapper.innerWidth();
    if (videoHasCta && dimensions.breakpoint > TABLET) {
      videoWidth -= 320;
      /* 300 CTA + 20 padding */
    }

    const videoHeight = Math.ceil(videoWidth / VIDEO_ASPECT_RATIO);
    $videoWrapper.height(videoHeight);
  }
}

class Hero {
  constructor () {
    this.templates = [];
    this.currentVideo = null;
  }

  init () {
    $('#bc-hero-container').on('click', '.hero-module', e => {
      e.preventDefault();
      this.playHeroVideo($(e.currentTarget));
    });
  }

  resize () {
    const $overlay = $('#bc-hero-player-overlay');
    const overlayWidth = $('#bc-hero-container .hero-module-0').width();
    const overlayHeight = $('#bc-hero-container .hero-module-0').height();

    $overlay.width(overlayWidth);
    $overlay.height(overlayHeight);
    $overlay.css('margin-left', -(overlayWidth / 2));
  }

  playHeroVideo ($heroModule) {
    $('.hero-module').removeClass('current');
    $heroModule.addClass('current');

    const dimensions = main.getDimensions();
    const videoId = $heroModule.attr('data-bc-video-id');

    if (dimensions.breakpoint === MOBILE) {
      const videoUrl = $heroModule.attr('data-bc-video-url');
      document.location.href = utils.urlWithDevice(videoUrl);
      return;
    }

    this.resize();
    this.loadVideo(videoId);
  }

  loadVideo (videoId) {
    const url = window.baseUrl + '/api/video/' + videoId;

    $.ajax(url, {
      dataType: 'json',
      success: data => {
        this.loadVideoDidFinish(data);
      },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  loadVideoDidFinish (data) {
    if (!data || !data.result) {
      return;
    }

    this.currentVideo = data.result;
    this.loadTemplate(utils.getPlayerTemplateUrl(), (template) => {
      const html = template({
        url: window.location.href,
        video: this.currentVideo,
        isSecure: window.location.protocol === 'https:',
        'user-agent': window.navigator.userAgent,
        autoplay: true,
        playerBackground: window.playerBackground,
        subPath: window.subPath,
        player: window.bc_gallery.player,
        site: window.site,
      });

      const $heroOverlay = $('#bc-hero-player-overlay');
      if ($heroOverlay.length) {
        teardownPlayers($heroOverlay.get(0));
        $heroOverlay.html(html).show();
        setupPlayers($heroOverlay.get(0));
        const player = window.videojs && window.videojs.players && window.videojs.players.performPlayer;
        if (!player) {
          return;
        }
        player.on('loadedmetadata', () => {
          if (!player.socialOverlay) {
            return;
          }
          player.socialOverlay.setDirectLink(data.result.canonicalUrl);
        });
      }
    });
  }
  loadTemplate (url, callback) {
    if (this.templates[url]) {
      return callback(this.templates[url]);
    }

    $.ajax({
      url: url,
      success: (contents) => {
        this.templates[url] = window.bcGallery.Handlebars.compile(contents);
        callback(this.templates[url]);
      },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }
}

class Carousel {

  init () {
    this.VIDEO_ASPECT_RATIO = 16 / 9;
    this.VIDEO_ASPECT_WIDTH = 16;
    this.VIDEO_ASPECT_HEIGHT = 9;
    this.currentVideo = null;
    this.currentVideoSlideIndex = -1;
    this.templates = [ ];
    this.resizeTimeout = 0;

    const slickOptions = {
      dots: true,
      infinite: true,
      draggable: true,
      centerMode: true,
      centerPadding: '15px',
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      useTransform: false,
      onAfterChange: () => { this.onAfterSlideChange(); },
    };

    if ($('.home-carousel').children().length === 1) {
      slickOptions.infinite = false;
    }

    $('.home-carousel').slick(slickOptions);

    $('.home-carousel').on('swipe', () => {
      header.stopPlayback();
    });

    main.imageLoader.loadImages();
    main.imageLoader.loaded = () => {
      this.onWindowResize();
      $('.home-carousel-info-mobile').show();
    };

  }

  resizeVideos () {
    $('.home-carousel-slide').each((key, value) => {
      const $this = $(value);
      const $videoContainer = $this.find('.home-carousel-video');
      $videoContainer.css('height', '');
      $videoContainer.css('width', '');
      const $video = $this.find('.bc-player');
      let width = $videoContainer.innerWidth();
      width = Math.floor(width / this.VIDEO_ASPECT_WIDTH) * this.VIDEO_ASPECT_WIDTH;
      const height = Math.floor(width / this.VIDEO_ASPECT_RATIO);
      $video.innerHeight(height + 'px');
      $video.innerWidth(width + 'px');

      const $image = $this.find('.bc-image-loader');
      $image.innerHeight(height + 'px');
    });
  }

  positionCarouselDots () {
    const $carousel = $('.home-carousel');
    const position = $carousel.find('.home-carousel-video').height() + 40;

    $carousel.find('.slick-dots').css({
      bottom: 'auto',
      top: position,
    });
  }

  onWindowResize () {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.resizeVideos();// order matters
      this.positionCarouselDots();// order matters
      detail.updateReadMore();
    }, 100);
  }
}

class SecondaryNav {
  init () {
    $('nav.main-nav ul li .trigger-dropdown').click((e) => {
      const $this = $(e.target);
      $this.siblings('ul').toggleClass('show-dropdown hide-dropdown');
      $this.parent().toggleClass('active-dropdown');
    });

    $('#bc-category-list-mobile ul li .trigger-dropdown').click((e) => {
      const $this = $(e.currentTarget);
      $this.siblings('ul').toggleClass('show-dropdown hide-dropdown');
      $this.parent().toggleClass('active-dropdown');
    });
  }
}

let liveVideoStatusInterval = null;
class LiveVideoStatus {
  constructor () {
    this.livePartial = '{{#isLiveProcessing liveStatus}}' +
      '<div class="video-live-now"><span></span><span>{{translations.liveNow}}</span></div>' +
        '{{/isLiveProcessing}}{{#isPreEvent liveStatus}}' +
        '<div class="video-live-title">{{translations.upcomingEvent}}</div>' +
      '{{/isPreEvent}}';
  }

  init () {
    const shouldAvoidLiveUpdates = window.isPreview;
    if (shouldAvoidLiveUpdates) {
      return;
    }
    liveVideoStatusInterval = setInterval(this.fetchLiveVideoStatus.bind(this), STATUS_UPDATE_INTERVAL);
  }

  fetchLiveVideoStatus () {
    $.ajax({
      url: window.baseUrl + '/api/livevideosstatus',
      dataType: 'json',
      success: this.handleSuccess.bind(this),
      error: this.handleError.bind(this),
    });
  }

  handleSuccess (data) {
    const shouldUpdateLiveStatus = Object
      .values(data.result)
      .some(status => status === liveStatus.WAITING || status === liveStatus.DISCONNECTED
        || liveStatus.PROCESSING);

    if (!shouldUpdateLiveStatus) {
      clearInterval(liveVideoStatusInterval);
      this.updateUI(data);
      // Reset interval to fetch upcoming events
      liveVideoStatusInterval = setInterval(this.fetchLiveVideoStatus.bind(this), STATUS_UPDATE_INTERVAL);
    } else if (liveVideoStatusInterval) {
      this.updateUI(data);
    }
  }

  updateUI (data) {
    const context = Object
      .keys(data.result)
      .map(videoId => ({ translations: window.translations, liveStatus: data.result[videoId], videoId }));

    const template = window.bcGallery.Handlebars.compile(this.livePartial);
    context.forEach(ctx => { $(`[data-live-id="${ctx.videoId}"]`).html(template(ctx)); });
  }

  handleError (error) {
    console.error(error);
  }
}

const main = new Main();
const header = new Header();
const detail = new Detail();
const hero = new Hero();
const carousel = new Carousel();
const secondaryNav = new SecondaryNav();
const liveVideoStatus = new LiveVideoStatus();

//---------------------------------------------------------------------------------
// Global
$(document).ready(function () {
  main.init();
  header.init();
  detail.init();
  hero.init();
  carousel.init();
  secondaryNav.init();
  main.imageLoader.loadImages();
  liveVideoStatus.init();
});

window.BCLS = window.BCLS || {};
window.BCLS.afterReadyHandler = function () {
  window.BCLS.modVP.play();
};

// Do not autoplay next on homepage
videoFinisher.onNextVideo(() => {
  if (window.location.pathname === '/') {
    return;
  }
  utils.nextVideo();
});
